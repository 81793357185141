<template>
  <div class="container">
    <div class="title">API对接</div>
    <div class="content">
      <div class="info">
        <div v-if="$store.state.userInfo.detail.auth_status == 2">
          <p class="info_item">
            <span class="item_title">App ID:</span>
            <span>{{$store.state.userInfo.detail.app_id}}</span>
          </p>
          <p class="info_item">
            <span class="item_title">App Secret:</span>
            <span>{{$store.state.userInfo.detail.app_secret}}</span>
          </p>
        </div>
        <p
          v-else-if="$store.state.userInfo.detail.auth_status == 0 || $store.state.userInfo.detail.auth_status == 3">
          您还未完成实名认证，无法获取信息 <span
            class="btn"
            @click="$router.push('/user/auth')"
          >去认证 <i class="el-icon-arrow-right"></i></span></p>
        <p v-else-if="$store.state.userInfo.detail.auth_status == 1">
          实名认证正在审核中，暂时无法获取信息，请审核通过后再来查看</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  // 组件名称
  name: '',
  // 局部注册的组件
  components: {},
  // 组件参数 接收来自父组件的数据
  props: {},
  // 组件状态值
  data () {
    return {
      tableSearchData: {

      }
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  created () {

  },
  mounted () { },
  // 组件方法
  methods: {

  }
}
</script>

<style scoped lang="less">
.container {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  .title {
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #ededed;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .content {
    // display: flex;
    height: calc(100% - 35px);
    box-sizing: border-box;
    .info {
      width: fit-content;
      margin: 80px auto;
      .btn {
        color: rgb(23, 44, 233);
        margin-left: 10px;
        cursor: pointer;
      }

      .info_item {
        margin-bottom: 15px;
        .item_title {
          display: inline-block;
          width: 100px;
          text-align: right;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
